import logo from './logo.svg';
import './App.css';
import Home from './cmp/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import Service from './cmp/services'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUs from './cmp/contact';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // 

function App() {
   
   useEffect(() => {
      AOS.init({
        duration: 800, // The duration of the animation in milliseconds
      });
    }, []);
  


  return (
     <>
     <BrowserRouter>
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Service />} />
        <Route path='/contactus' element={<ContactUs />} />

        </Routes>
     
     
     </BrowserRouter>
     
     </>
  );
}

export default App;
