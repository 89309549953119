import React from 'react'
import { Nav, Navbar, Container, Dropdown, DropdownButton, Carousel } from 'react-bootstrap';
import Phone from '../cmp/phone'
import '../cmp/ourtes.css';
import {Link} from 'react-router-dom'
const header = () => {
    const customStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        background: '#1C3C74'
      };


  return (
    <>
      <div className='container-fluid bg-white' >
                <div className='container mob-res'>
                    <div className='row py-2 px-4 mob-res'>
                        <div className='col-md-6 text-start py-1 mt-2'>
                            <span className='fw-bold'>
                                <span className='fs-5 text-white'>Follows Us : </span>
                               
                                <span className='mx-2' style={{ width: '70px', height: '70px', backgroundColor: '#3B5998', padding: '10px 15px', borderRadius: '50%' }}>
                                        <a className='text-white' target='_blank' href="https://www.facebook.com/kkfs.offl/">
                                            <i class="fa fa-facebook fs-5"></i></a></span>
                                   

                                    <span className='mx-2' style={{ width: '70px', height: '70px', backgroundColor: '#3B77B5', padding: '10px 13px', borderRadius: '50%' }}>
                                    <a class="text-white" target='_blank' href="https://www.linkedin.com/company/76200284/admin/inbox/">
                           
                                 
                                        <i className='fa fa-linkedin fs-5'></i>
                                        </a>
                                    </span>
                                    <span className='mx-2' style={{ width: '70px', height: '70px', backgroundColor: '#52A1F3', padding: '10px 12px', borderRadius: '50%' }}>
                                        <a class="text-white" target='_blank' href="https://twitter.com/home">


                                            <i className='fa fa-twitter fs-5'></i>
                                        </a>
                                    </span>
                           </span>
                           
                        </div>

                        <div className='col-md-6  text-end py-1'>
                            <div className='py-1 mt-2 d-flex justify-content-end align-items-center mob-dis'>
                                <button className='rounded-pill px-3 py-2 me-3 text-white mb-3 border-0' style={{ backgroundColor: '#E62E5E' }}><span className='fs-5'><i className='fa fa-envelope me-1 fs-5 text-white'></i>Send Message</span></button>
                               <div className=''><span className='fs-5  d-flex align-items-center'><Phone />+91 8494941209</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid' style={customStyle}>
                <div className='container mob-res'>

                    <div className='row'>
                        <div className='col-md-12 px-5 mob-res'>
                            <div>

                                <Navbar expand="lg"   className="bg-body-tertiary py-0">
                                    <Container fluid className=' py-2' style={{ background: '#1C3C74' }}>
                                        <Navbar.Brand href="/" className='logo-mob'><img src='com-logo.jpeg' className='me-3' style={{ width: '148px' }} /><span className='fw-bold fs-1' style={{color:'#E62E5E', fontFamily:'italic'}}>KK Financial Service</span></Navbar.Brand>
                                        <Navbar.Toggle aria-controls="navbarScroll" />
                                        <Navbar.Collapse id="navbarScroll">
                                            <div className=' d-flex justify-content-end' style={{ width: '100%' }}>
                                                <Nav
                                                    className="my-2 my-lg-0"
                                                    style={{ maxHeight: '300px' }}
                                                    navbarScroll
                                                >

                                                    <Nav.Link href='/' className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Home</Nav.Link>
                                                    <Nav.Link href="#about-us" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>About Us</Nav.Link>

                                                    {/* <DropdownButton id="dropdown-basic-button" title="Products & Solutions" className='fw-bold'>
                                                   
                                                    <Dropdown.Item as={Link} to="/power_electronic">	</Dropdown.Item>
                                                    <Dropdown.Item  as={Link} to="/microwave_and_communication">	</Dropdown.Item>
                                                    <Dropdown.Item  as={Link} to="/battery_simulators"> </Dropdown.Item>
                                                  
                                                    <Dropdown.Item as={Link} to="/general_purpose_equipments"></Dropdown.Item>
                                                </DropdownButton> */}
                                                   
                                                    <Link to="/services" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Services</Link>
                                                    <Link to="/contactus" className='nav-link me-2 fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Contact Us</Link>
                                                    {/* <input type='search' placeholder='Search.....' style={{ borderRadius: '30px', padding: '0px 10px', outline: 'none' }} /> */}

                                                </Nav>
                                            </div>
                                            {/* <Form className="d-flex">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                className="me-2"
                                                aria-label="Search"
                                            />
                                            <Button variant="outline-success">Search</Button>
                                        </Form> */}
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                    {/* <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#">Navbar</a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#">Link</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                </nav> */}
                </div>
            </div>


    </>
  )
}

export default header