
import Footer from '../cmp/Footer'
import Header from '../cmp/header'
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactServic from './contact-home'
const services = () => {
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 item on screens smaller than 600px
        },
        600: {
            items: 2, // Show 2 items on screens between 600px and 1000px
        },
        1000: {
            items: 3, // Show 3 items on screens larger than 1000px
        },
    }
    return (
        <>
            <Header />
            <div className='container py-5'>
                <div className='text-center mb-5'>
                    <h3>Our Services</h3>
                    <h1 className='display-5' style={{ color: '#5918B0' }}>We're Here To Make Your Loan Happen</h1>
                </div>

                <div className='row'>
                    <div className='col-md-4 mb-4'>
                        <div class="card border-0 shadow">
                            <div className='text-center'> <img src="house.gif" class="card-img-top w-25 py-3" alt="..." /></div>
                            <div class="card-body  text-white text-center" style={{ backgroundColor: '#E9652D' }}>
                                <h4 class="card-title text-black">Home Loan/ Mortagage Loan</h4>
                                <p class="card-text fs-5">
                                    Manage your venture's planned and unplanned expenses. From leasing a larger office premise to renovating your workspace, you can use it for various purposes.
                                </p>
                                <button className='btn btn-info'><a  className='text-decoration-none text-white' href='hone.pdf'>Read more</a></button>

                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 mb-5'>
                        <div class="card border-0 shadow"  >
                            <div className='text-center'><img src="handshake.gif" class="card-img-top w-25 py-3" alt="..." /></div>
                            <div class="card-body  text-white text-center" style={{ backgroundColor: '#E9652D' }}>
                                <h4 class="card-title text-black">Personal Loan</h4>
                                <p class="card-text fs-5">
                                    A Personal Loan is ideal for funding planned events, such as marriages, vacations & home renovation or unplanned ones, like emergency medical procedures.
                                </p>
                                <button className='btn btn-info'><a  className='text-decoration-none text-white' href='per.pdf'>Read more</a></button>

                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 mb-5'>
                        <div class="card border-0 shadow"  >
                            <div className='text-center'><img src="line-chart.gif" class="card-img-top w-25 py-3" alt="..." /></div>
                            <div class="card-body  text-white text-center" style={{ backgroundColor: '#E9652D' }}>
                                <h4 class="card-title text-black">Business Loan</h4>
                                <p class="card-text fs-5 pb-4">
                                    Get digital sanction of your Home Loan at attractive interest rate in few simple steps. Enjoy hassle-free disbursement with ICICI Bank Home Loan.
                                </p>
                                <button className='btn btn-info'><a  className='text-decoration-none text-white' href='bis.pdf'>Read more</a></button>

                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 mb-5'>
                        <div class="card border-0 shadow" >
                            <div className='text-center'><img src="edu.gif" class="card-img-top w-25 py-3" alt="..." style={{}} /></div>
                            <div class="card-body  text-white text-center" style={{ backgroundColor: '#E9652D' }} >
                                <h4 class="card-title text-black">Education Loan</h4>
                                <p class="card-text fs-5 pb-5">
                                    Education Loans for studies both in India and abroad starting from  10lakhs at attractive interest rates. Education Loan you can enjoy a host of benefits such as simple documentation, quick loan disbursal, tax benefit u/s 80(E), long repayment tenure.
                                </p>
                                <button className='btn btn-info'><a  className='text-decoration-none text-white' href='edu.pdf'>Read more</a></button>

                            </div>
                        </div>
                    </div>


                    <div className='col-md-4 mb-5'>
                        <div class="card border-0 shadow" >
                            <div className='text-center'><img src="convertible.gif" class="card-img-top w-25 py-3" alt="..." /></div>
                            <div class="card-body  text-white text-center" style={{ backgroundColor: '#E9652D' }} >
                                <h4 class="card-title text-black mb-3">Car Loan</h4>
                                <p class="card-text fs-5 mb-4 pb-5">
                                    Every common man dreams of a car they wish to own and ride as soon as they start earning Car Loans allow you to make this dream of your come true at the Lowest Car Loan Interest Rates and minimum documentation.
                                </p>
                                <button className='btn btn-info'><a  className='text-decoration-none text-white' href='car.pdf'>Read more</a></button>

                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 mb-5'>
                        <div class="card border-0 shadow">
                            <div className='text-center'><img src="sch.gif" class="card-img-top w-25 py-3" alt="..." /></div>
                            <div class="card-body  text-white text-center" style={{ backgroundColor: '#E9652D' }} >
                                <h4 class="card-title text-black">School Infra-Structure Funding Loan</h4>
                                <p class="card-text fs-5">
                                    Funding for school infrastructure projects typically involves securing a loan or grant to support the construction, renovation, or improvement of educational facilities. The specific process and options for obtaining. Here are some general steps to consider when seeking funding for school infrastructure projects.
                                </p>
                                <button className='btn btn-info'><a  className='text-decoration-none text-white' href='sch-edu.pdf'>Read more</a></button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='container'>
                <h2 className='fs-3'> 1. Require Document For Taking Personal Loan</h2>
                <div>
                    <ul>
                        <li className='fs-5'>
                            PAN CARD COLOR
                        </li>
                        <li className='fs-5'>
                            AADHAR CARD
                        </li>
                        <li className='fs-5'>
                            PASSPORT SIZE PHOTO
                        </li>
                    </ul>
                    <ul>
                        <p className='fs-5 fw-bold'>ADDRESS PROOF OF RESIDENCE:  Either One Submit </p>
                        <li className='fs-5'>
                            GAS BILL
                        </li>
                        <li className='fs-5'>
                            RENTAL AGREEMENT DULY NOTARISED  AND ELECTRICTY
                        </li>
                        <li className='fs-5'>
                            RATIONA CARD
                        </li>
                        <li className='fs-5'>
                            AADHAR CARD
                        </li>
                        <li className='fs-5'>
                            VOTER ID
                        </li>
                        <li className='fs-5'>
                            PASSPORT

                        </li>
                        <li className='fs-5'>
                            DRIVING LIECENCE

                        </li>
                        <li className='fs-5'>
                            Savings ac statement last 6 months, e-bill, House  rental agreement,
                        </li>
                        <li className='fs-5'>
                            LIC premium  receipt  & Address Proof
                        </li>
                    </ul>
                    <ul>
                        <p className='fs-5 fw-bold'>Employment  </p>
                        <li className='fs-5'>
                            Salary slip for the latest 3 months / Salary Certificate
                        </li>
                        <li className='fs-5'>
                            slips for  the past 6 months are required
                        </li>
                        <li className='fs-5'>
                            Existing Loan Track Records
                        </li>

                    </ul>


                </div>
            </div> */}
            {/* <div className='container'>
                <h2 className='fs-3'>2. Require Document For Taking Business Loan</h2>
                <div>
                    <ul>
                        <li className='fs-5'>
                            MSMe  regsigtration of firm
                        </li>
                        <li className='fs-5'>
                            GST certificate
                        </li>
                        <li className='fs-5'>
                            shop / busienss bbmp licence
                        </li>
                        <li className='fs-5'>
                            Aadhar card applicant  and wife aadhar card , email id  and phone number
                        </li>
                        <li className='fs-5'>
                            PAN Card (Individual and Company) and wife /spouse pan card email id and phone number
                        </li>
                        <li className='fs-5'>
                            3 years ITR (Individual and Company)
                        </li>
                        <li className='fs-5'>
                            1 year Bank statement (Individual and Company)
                        </li>
                        <li className='fs-5'>
                            1 year GST
                        </li>
                        <li className='fs-5'>
                            24 months gst retruns current bill 3B

                        </li>
                        <li className='fs-5'>
                            own house proof   and Property Tax

                        </li>

                    </ul>


                </div>
            </div> */}
            {/* <div className='container'>
                <h2 className='fs-3'> 3. Require Document For Taking Education Loan</h2>
                 <div>
                    <ul>
                        <li className='fs-5'>
                        Aadhaar card
                        </li>
                        <li className='fs-5'>
                        PAN card
                        </li>
                        <li className='fs-5'>
                        Driving License
                        </li>
                        
                       
                           
                        <li className='fs-5'>
                        Address Proof
                        </li>
                        <li className='fs-5'>
                        Valid electricity/water/LPG bill
                        </li>
                        <li className='fs-5'>
                        Valid passport issued by GOI (Government of India)
                        </li>
                        <li className='fs-5'>
                        Voter’s ID card (not accepted by all banks)
                        </li>
                        <li className='fs-5'>
                        Current house lease agreement
                        </li>
                        <li className='fs-5'>
                        Magistrate/Gram Panchayat affidavit (not applicable for all banks)

                        </li>
                        <li className='fs-5'>
                        Certificate of domicile issued by district magistrate office

                        </li>
                        <li className='fs-5'>
                        Gazzeted affidavit (not applicable for all banks)
                        </li>
                        <li className='fs-5'>
                        Permanent residence certificate issued by competent State/UT authority
                        </li>
                        </ul>
                        <ul>
                            <p className='fs-5 fw-bold'>Employment  </p>
                        <li className='fs-5'>
                        Salary slip for the latest 3 months / Salary Certificate 
                        </li>
                        <li className='fs-5'>
                        slips for  the past 6 months are required
                        </li>
                        <li  className='fs-5'>
                        Existing Loan Track Records
                        </li>

                    </ul>


                </div> 
            </div> */}

            {/* <div className='container'>
                <h2 className='fs-3'>3. Require Document For Taking Business Loan</h2>
                <div>
                    <ul>
                        <li className='fs-5'>
                            MSMe  regsigtration of firm
                        </li>
                        <li className='fs-5'>
                            GST certificate
                        </li>
                        <li className='fs-5'>
                            shop / busienss  licence
                        </li>
                        <li className='fs-5'>
                            Aadhar card of applicant  and spouse aadhar card , email id  and phone number
                        </li>
                        <li className='fs-5'>
                            PAN Card (Individual and Company) and wife /spouse pan card email id and phone number
                        </li>
                        <li className='fs-5'>
                            3 years ITR (Individual and Company)
                        </li>
                        <li className='fs-5'>
                            1 year Bank statement (Individual and Company)
                        </li>
                        <li className='fs-5'>
                            1  year 3B GST Returns
                        </li>
                       
                        <li className='fs-5'>
                            own house proof   and Property Tax

                        </li>

                    </ul>


                </div>
            </div> */}


            <div className='container py-4 mb-4'>
                <h3 className='text-center fs-1'><span style={{ borderBottom: '3px solid red' }}>Home ,Personal And Business Loan Partners</span></h3>
                <div className=''>
                    <div>
                        <div class='container-fluid' >
                            <div className="row title" style={{ marginBottom: "20px" }} >

                            </div>
                        </div>


                        <div class='container-fluid' >
                            <OwlCarousel items={5}
                                className="owl-theme"
                                loop
                                nav
                                margin={20}
                                autoplay={1000}
                                responsive={responsiveOptions}
                            >
                                <div >

                                    {/* <img className="img" src={'personal_loan.webp'} /> */}
                                    <div class="card border-0 shadow">
                                        <img src={'hdfc.png'} class="card-img-top" alt="..." style={{ height: '80px' ,width:'200px'}} />

                                    </div>
                                </div>


                                <div>
                                    <div class="card border-0 shadow"  >
                                        <img src="kotak.png" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>


                                <div>
                                    <div class="card border-0 shadow" >
                                       <div className='text-center'> <img src="icici.png" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} /></div>

                                    </div>

                                </div>



                                <div>

                                    <div class="card border-0 shadow" >
                                        <img src="hdfc.png" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>
                                </div>


                                <div>
                                    <div class="card border-0 shadow">
                                        <img src="icici.png" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>
                                <div>
                                    <div class="card border-0 shadow">
                                        <img src="kotak.png" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>

                            </OwlCarousel>
                        </div>

                    </div>

                </div>


            </div>

            <div className='container py-4 mb-4'>
                <h3 className='text-center fs-1'><span style={{ borderBottom: '3px solid red' }}>Education Loan Partners</span></h3>
                <div className=''>
                    <div>
                        <div class='container-fluid' >
                            <div className="row title" style={{ marginBottom: "20px" }} >

                            </div>
                        </div>


                        <div class='container-fluid' >
                            <OwlCarousel items={5}
                                className="owl-theme"
                                loop
                                nav
                                margin={20}
                                autoplay={1000}
                                responsive={responsiveOptions}
                            >
                                <div >

                                    {/* <img className="img" src={'personal_loan.webp'} /> */}
                                    <div class="card border-0 shadow">
                                        <img src={'loan-par-1.jpeg'} class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>
                                </div>


                                <div>
                                    <div class="card border-0 shadow">
                                        <img src="loan-par-2.jpeg" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>


                                <div>
                                    <div class="card border-0 shadow" >
                                        <img src="loan-par-3.jpeg" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>



                                <div>

                                    <div class="card border-0 shadow"  >
                                        <img src="loan-par-1.jpeg" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>
                                </div>


                                <div>
                                    <div class="card border-0 shadow" >
                                        <img src="loan-par-2.jpeg" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>
                                <div>
                                    <div class="card border-0 shadow" >
                                        <img src="loan-par-3.jpeg" class="card-img-top" alt="..." style={{ height: '80px',width:'200px' }} />

                                    </div>

                                </div>







                            </OwlCarousel>
                        </div>

                    </div>

                </div>


            </div>
            <ContactServic />

            <Footer />

        </>
    )
}

export default services