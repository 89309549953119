import React from 'react';

class CallIcon extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneNumber: '8498941209', // Replace with your desired phone number
    };
  }

  handleCallClick = () => {
    if (this.state.phoneNumber) {
      window.open(`tel:${this.state.phoneNumber}`);
    } else {
      console.error("Phone number is undefined or empty");
    }
  };


  
    handleWhatsAppClick = () => {
      const whatsappUrl = `https://wa.me/${this.state.phoneNumber}`;
      window.open(whatsappUrl, '_blank');
    };
   

  render() {

   
    
      
    return (
      <div>
        <button onClick={this.handleCallClick} style={{backgroundColor:'inherit' ,border:'none'}}>
         <i className='fa fa-phone me-1 fs-5 ' style={{ backgroundColor: '#E62E5E', borderRadius: '50%', padding: '11px 12px', color: 'white', cursor: 'pointer' }}></i>
        </button>
      </div>
    );
  }
}

export default CallIcon;

