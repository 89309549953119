import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../cmp/ourtes.css';

const Ourtesstomany = () => {

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 item on screens smaller than 600px
        },
        600: {
            items: 2, // Show 2 items on screens between 600px and 1000px
        },
        1000: {
            items: 3, // Show 3 items on screens larger than 1000px
        },
    }
    return (
        <>
            <div>
                <div class='container-fluid' >
                    <div className="row title" style={{ marginBottom: "20px" }} >

                    </div>
                </div>
                <div class='container-fluid' >
                    <div className='container'>
                        <OwlCarousel items={3}
                            className="owl-theme"
                            loop
                            nav
                            margin={20}
                            autoplay={4000}
                            responsive={responsiveOptions}
                        >
                            <div >

                                {/* <img className="img" src={'personal_loan.webp'} /> */}
                                <div class="card border-0 shadow" style={{ backgroundColor: '#3F4249' }}>

                                    <div class="card-body  text-white text-center">

                                        <p class="card-text py-3" style={{ color: '#c1c2c7' }}>
                                        "I am extremely pleased with kk Finance service. The process was seamless, rates were competitive, and the personalized attention to my financial needs was impressive"
                                        </p>

                                    </div>

                                </div>

                                <div className='d-flex p-3 align-items-center'>
                                    <div className='client-pic me-4'>
                                        <img src='author-3.jpg' style={{ borderRadius: '50%' }} />
                                    </div>
                                    <div>
                                        <h5 className='text-white'>Kartik Aryan</h5>
                                        <p className='text-info fs-5'>Benglore</p>
                                    </div>
                                </div>
                            </div>


                            <div>

                                <div class="card border-0 shadow" style={{ backgroundColor: '#3F4249' }}>

                                    <div class="card-body  text-white text-center">

                                        <p class="card-text py-3" style={{ color: '#c1c2c7' }}>
                                        "Exceptional service from kk Finance service! Smooth process, competitive rates, and attentive to my financial goals. Quick and clear communication made it a positive experience. Highly recommend!"
                                        </p>

                                    </div>

                                </div>

                                <div className='d-flex p-3 align-items-center'>
                                    <div className='client-pic me-4'>
                                        <img src='test-1.jpg' style={{ borderRadius: '50%' }} />
                                    </div>
                                    <div>
                                        <h5 className='text-white'>Murgan Pali</h5>
                                        <p className='text-info fs-5'>Tamilnadu</p>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div class="card border-0 shadow" style={{ backgroundColor: '#3F4249' }}>

                                    <div class="card-body  text-white text-center">

                                        <p class="card-text py-3" style={{ color: '#c1c2c7' }}>
                                        "Thumbs up for kk Finance service! Their professionalism, and personalized approach impressed me. The process was smooth, and the clear communication. Highly recommended for a  loan!"
                                        </p>

                                    </div>

                                </div>

                                <div className='d-flex p-3 align-items-center'>
                                    <div className='client-pic me-4'>
                                        <img src='test-2.jpg' style={{ borderRadius: '50%' }} />
                                    </div>
                                    <div>
                                        <h5 className='text-white'>Rahul singh</h5>
                                        <p className='text-info fs-5'>Bihar</p>
                                    </div>
                                </div>

                            </div>



                           


                            <div>
                                <div class="card border-0 shadow" style={{ backgroundColor: '#3F4249' }}>

                                    <div class="card-body  text-white text-center">

                                        <p class="card-text py-3" style={{ color: '#c1c2c7' }}>
                                        "Big shoutout to kk Finance service! Seamless process, great rates, and personalized service. Their clear communication and efficiency made securing a loan a breeze. Highly satisfied and definitely recommend!"
                                        </p>

                                    </div>

                                </div>

                                <div className='d-flex p-3 align-items-center'>
                                    <div className='client-pic me-4'>
                                        <img src='test-3.jpeg' style={{ borderRadius: '50%' }} />
                                    </div>
                                    <div>
                                        <h5 className='text-white'>Raghendra </h5>
                                        <p className='text-info fs-5'>Utter Pradesh</p>
                                    </div>
                                </div>

                            </div>

                        </OwlCarousel>
                    </div>
                </div>

            </div>
            
        </>
    )
}

export default Ourtesstomany